.fc .fc-datagrid-cell-cushion {
    padding: 0px;
}

.fc-view {
    font-size: 0.8rem;
}

.fc .fc-toolbar-title {
    font-size: 1.25em;
}

.fc-toolbar .btn {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em;
}

.fc .fc-timeline-slot-cushion {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fc-timeline-overlap-disabled .fc-timeline-event {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
}

.fc-day-sat,
.fc-day-sun,
.fc-slot-sat,
.fc-slot-sun {
    background-color: rgba(0, 0, 0, 0.15);
}

#external-events-projects {
    height: 35vh;
    overflow-y: scroll;
}

#external-events-projects .fc-event {
    max-width: 185px;
}

#external-events-planningitemtypes {
    height: 15vh;
    overflow-y: scroll;
}

#external-events-planningitemtypes .fc-event {
    max-width: 185px;
}

.resource_foreman {
    background-color: #ffff78;
}

.resource_supplier {
    background-color: #c7f272;
}

.trash:hover {
    background-color: red;
}

.fc-day {
    border-left-color: #b5bfca !important;
}

.fc-slot-mon:nth-child(odd),
.fc-slot-tue:nth-child(odd),
.fc-slot-wed:nth-child(odd),
.fc-slot-thu:nth-child(odd),
.fc-slot-fri:nth-child(odd),
.fc-slot-sat:nth-child(odd),
.fc-slot-sun:nth-child(odd) {
    border-left-color: #b5bfca !important;
}

.project-00 {
    background-color: #696969;
}

.project-00 .fc-event-title {
    color: white;
}

.project-01 {
    background-color: #808080;
}

.project-01 .fc-event-title {
    color: black;
}

.project-02 {
    background-color: #a9a9a9;
}

.project-02 .fc-event-title {
    color: black;
}

.project-03 {
    background-color: #c0c0c0;
}

.project-03 .fc-event-title {
    color: black;
}

.project-04 {
    background-color: #dcdcdc;
}

.project-04 .fc-event-title {
    color: black;
}

.project-05 {
    background-color: #2f4f4f;
}

.project-05 .fc-event-title {
    color: white;
}

.project-06 {
    background-color: #556b2f;
}

.project-06 .fc-event-title {
    color: white;
}

.project-07 {
    background-color: #8b4513;
}

.project-07 .fc-event-title {
    color: white;
}

.project-08 {
    background-color: #6b8e23;
}

.project-08 .fc-event-title {
    color: white;
}

.project-09 {
    background-color: #a0522d;
}

.project-09 .fc-event-title {
    color: white;
}

.project-10 {
    background-color: #a52a2a;
}

.project-10 .fc-event-title {
    color: white;
}

.project-11 {
    background-color: #2e8b57;
}

.project-11 .fc-event-title {
    color: white;
}

.project-12 {
    background-color: #228b22;
}

.project-12 .fc-event-title {
    color: white;
}

.project-13 {
    background-color: #800000;
}

.project-13 .fc-event-title {
    color: white;
}

.project-14 {
    background-color: #191970;
}

.project-14 .fc-event-title {
    color: white;
}

.project-15 {
    background-color: #006400;
}

.project-15 .fc-event-title {
    color: white;
}

.project-16 {
    background-color: #808000;
}

.project-16 .fc-event-title {
    color: white;
}

.project-17 {
    background-color: #483d8b;
}

.project-17 .fc-event-title {
    color: white;
}

.project-18 {
    background-color: #b22222;
}

.project-18 .fc-event-title {
    color: white;
}

.project-19 {
    background-color: #5f9ea0;
}

.project-19 .fc-event-title {
    color: black;
}

.project-20 {
    background-color: #778899;
}

.project-20 .fc-event-title {
    color: black;
}

.project-21 {
    background-color: #3cb371;
}

.project-21 .fc-event-title {
    color: black;
}

.project-22 {
    background-color: #bc8f8f;
}

.project-22 .fc-event-title {
    color: black;
}

.project-23 {
    background-color: #663399;
}

.project-23 .fc-event-title {
    color: white;
}

.project-24 {
    background-color: #008080;
}

.project-24 .fc-event-title {
    color: white;
}

.project-25 {
    background-color: #b8860b;
}

.project-25 .fc-event-title {
    color: black;
}

.project-26 {
    background-color: #bdb76b;
}

.project-26 .fc-event-title {
    color: black;
}

.project-27 {
    background-color: #cd853f;
}

.project-27 .fc-event-title {
    color: black;
}

.project-28 {
    background-color: #4682b4;
}

.project-28 .fc-event-title {
    color: white;
}

.project-29 {
    background-color: #000080;
}

.project-29 .fc-event-title {
    color: white;
}

.project-30 {
    background-color: #d2691e;
}

.project-30 .fc-event-title {
    color: white;
}

.project-31 {
    background-color: #9acd32;
}

.project-31 .fc-event-title {
    color: black;
}

.project-32 {
    background-color: #20b2aa;
}

.project-32 .fc-event-title {
    color: black;
}

.project-33 {
    background-color: #cd5c5c;
}

.project-33 .fc-event-title {
    color: white;
}

.project-34 {
    background-color: #4b0082;
}

.project-34 .fc-event-title {
    color: white;
}

.project-35 {
    background-color: #32cd32;
}

.project-35 .fc-event-title {
    color: black;
}

.project-36 {
    background-color: #daa520;
}

.project-36 .fc-event-title {
    color: black;
}

.project-37 {
    background-color: #8fbc8f;
}

.project-37 .fc-event-title {
    color: black;
}

.project-38 {
    background-color: #800080;
}

.project-38 .fc-event-title {
    color: white;
}

.project-39 {
    background-color: #b03060;
}

.project-39 .fc-event-title {
    color: white;
}

.project-40 {
    background-color: #66cdaa;
}

.project-40 .fc-event-title {
    color: black;
}

.project-41 {
    background-color: #ff0000;
}

.project-41 .fc-event-title {
    color: white;
}

.project-42 {
    background-color: #9932cc;
}

.project-42 .fc-event-title {
    color: white;
}

.project-43 {
    background-color: #ff4500;
}

.project-43 .fc-event-title {
    color: white;
}

.project-44 {
    background-color: #00ced1;
}

.project-44 .fc-event-title {
    color: black;
}

.project-45 {
    background-color: #ff8c00;
}

.project-45 .fc-event-title {
    color: black;
}

.project-46 {
    background-color: #ffa500;
}

.project-46 .fc-event-title {
    color: black;
}

.project-47 {
    background-color: #ffd700;
}

.project-47 .fc-event-title {
    color: black;
}

.project-48 {
    background-color: #6a5acd;
}

.project-48 .fc-event-title {
    color: white;
}

.project-49 {
    background-color: #ffff00;
}

.project-49 .fc-event-title {
    color: black;
}

.project-50 {
    background-color: #c71585;
}

.project-50 .fc-event-title {
    color: white;
}

.project-51 {
    background-color: #0000cd;
}

.project-51 .fc-event-title {
    color: white;
}

.project-52 {
    background-color: #7cfc00;
}

.project-52 .fc-event-title {
    color: black;
}

.project-53 {
    background-color: #deb887;
}

.project-53 .fc-event-title {
    color: black;
}

.project-54 {
    background-color: #40e0d0;
}

.project-54 .fc-event-title {
    color: black;
}

.project-55 {
    background-color: #00ff00;
}

.project-55 .fc-event-title {
    color: black;
}

.project-56 {
    background-color: #9400d3;
}

.project-56 .fc-event-title {
    color: white;
}

.project-57 {
    background-color: #ba55d3;
}

.project-57 .fc-event-title {
    color: black;
}

.project-58 {
    background-color: #00fa9a;
}

.project-58 .fc-event-title {
    color: black;
}

.project-59 {
    background-color: #8a2be2;
}

.project-59 .fc-event-title {
    color: white;
}

.project-60 {
    background-color: #00ff7f;
}

.project-60 .fc-event-title {
    color: black;
}

.project-61 {
    background-color: #4169e1;
}

.project-61 .fc-event-title {
    color: white;
}

.project-62 {
    background-color: #e9967a;
}

.project-62 .fc-event-title {
    color: black;
}

.project-63 {
    background-color: #dc143c;
}

.project-63 .fc-event-title {
    color: white;
}

.project-64 {
    background-color: #00ffff;
}

.project-64 .fc-event-title {
    color: black;
}

.project-65 {
    background-color: #00bfff;
}

.project-65 .fc-event-title {
    color: black;
}

.project-66 {
    background-color: #f4a460;
}

.project-66 .fc-event-title {
    color: black;
}

.project-67 {
    background-color: #9370db;
}

.project-67 .fc-event-title {
    color: black;
}

.project-68 {
    background-color: #0000ff;
}

.project-68 .fc-event-title {
    color: white;
}

.project-69 {
    background-color: #a020f0;
}

.project-69 .fc-event-title {
    color: white;
}

.project-70 {
    background-color: #f08080;
}

.project-70 .fc-event-title {
    color: black;
}

.project-71 {
    background-color: #adff2f;
}

.project-71 .fc-event-title {
    color: black;
}

.project-72 {
    background-color: #ff6347;
}

.project-72 .fc-event-title {
    color: black;
}

.project-73 {
    background-color: #C34ABE;
}

.project-73 .fc-event-title {
    color: black;
}

.project-74 {
    background-color: #d8bfd8;
}

.project-74 .fc-event-title {
    color: black;
}

.project-75 {
    background-color: #b0c4de;
}

.project-75 .fc-event-title {
    color: black;
}

.project-76 {
    background-color: #ff7f50;
}

.project-76 .fc-event-title {
    color: black;
}

.project-77 {
    background-color: #ff00ff;
}

.project-77 .fc-event-title {
    color: white;
}

.project-78 {
    background-color: #1e90ff;
}

.project-78 .fc-event-title {
    color: white;
}

.project-79 {
    background-color: #db7093;
}

.project-79 .fc-event-title {
    color: black;
}

.project-80 {
    background-color: #f0e68c;
}

.project-80 .fc-event-title {
    color: black;
}

.project-81 {
    background-color: #fa8072;
}

.project-81 .fc-event-title {
    color: black;
}

.project-82 {
    background-color: #eee8aa;
}

.project-82 .fc-event-title {
    color: black;
}

.project-83 {
    background-color: #ffff54;
}

.project-83 .fc-event-title {
    color: black;
}

.project-84 {
    background-color: #6495ed;
}

.project-84 .fc-event-title {
    color: black;
}

.project-85 {
    background-color: #dda0dd;
}

.project-85 .fc-event-title {
    color: black;
}

.project-86 {
    background-color: #90ee90;
}

.project-86 .fc-event-title {
    color: black;
}

.project-87 {
    background-color: #add8e6;
}

.project-87 .fc-event-title {
    color: black;
}

.project-88 {
    background-color: #87ceeb;
}

.project-88 .fc-event-title {
    color: black;
}

.project-89 {
    background-color: #ff1493;
}

.project-89 .fc-event-title {
    color: white;
}

.project-90 {
    background-color: #7b68ee;
}

.project-90 .fc-event-title {
    color: white;
}

.project-91 {
    background-color: #ffa07a;
}

.project-91 .fc-event-title {
    color: black;
}

.project-92 {
    background-color: #afeeee;
}

.project-92 .fc-event-title {
    color: black;
}

.project-93 {
    background-color: #ee82ee;
}

.project-93 .fc-event-title {
    color: black;
}

.project-94 {
    background-color: #87cefa;
}

.project-94 .fc-event-title {
    color: black;
}

.project-95 {
    background-color: #7fffd4;
}

.project-95 .fc-event-title {
    color: black;
}

.project-96 {
    background-color: #ffdead;
}

.project-96 .fc-event-title {
    color: black;
}

.project-97 {
    background-color: #ff69b4;
}

.project-97 .fc-event-title {
    color: black;
}

.project-98 {
    background-color: #ffe4c4;
}

.project-98 .fc-event-title {
    color: black;
}

.project-99 {
    background-color: #ffb6c1;
}

.project-99 .fc-event-title {
    color: black;
}

.project-mkj {
    background-image: repeating-linear-gradient(45deg, #00000000, #00000000 5px, #00000040 5px, #00000040 10px);
}