body {
  padding-top: 4rem;
}

.alert-pre {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
}

.btn-in-table {
  line-height: 1 !important;
}

.rides-weekend {
  background-color: lightgray;
}

.rides-days .row {
  border-bottom: 1px solid lightgray;
}

.rides-days .row:last-child {
  border-bottom: none;
}

.text-warningcustom {
  color: orange;
}
